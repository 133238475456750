@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

  .sidebar-icon {
    @apply cursor-pointer;
  }

  .sidebar-icon>.icon {
    @apply relative flex items-center justify-center 
    h-12 w-12 mt-2 mb-1 mx-auto 
    shadow-lg bg-primary text-secondary rounded-full hover:bg-primary_highlight; 
  }

  .sidebar-icon>.text {
    @apply w-12 mb-2 mx-auto 
  text-primary text-center hover:text-primary_highlight; 
  }

  .sidebar-icon.small>.icon {
    @apply h-7 w-7;
  }

  .sidebar-icon.small>.text {
    font-size: 70%;
  }

  .icon.selected {
    @apply bg-selected hover:bg-selected_highlight;
  }

  .text.selected {
    @apply text-selected hover:text-selected_highlight;
  }

  .map-tooltip {
    max-width: 300px;
    white-space: normal;
  }

  .invisible-file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .invisible-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .invisible-file-input::before {
    content: 'Upload files';
    display: inline-block;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

/* FONTS */
@font-face {
  font-family: 'Messina Sans';
  src: url('./assets/font/MessinaSans-Bold.woff2') format('woff2'),
       url('./assets/font/MessinaSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Messina Sans';
  src: url('./assets/font/MessinaSans-Regular.woff2') format('woff2'),
       url('./assets/font/MessinaSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Messina Sans';
  src: url('./assets/font/MessinaSans-Light.woff2') format('woff2'),
       url('./assets/font/MessinaSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

html {
  font-family: 'Messina Sans';
}